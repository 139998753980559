import React, { useEffect } from 'react';

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

const GoogleAds: React.FC = () => {
  // This hook will run after the component is mounted
  useEffect(() => {
    // Dynamically load the Google Ads script in the document head
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3308299494607073';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);

    // Once the script is loaded, trigger the adsbygoogle push
    script.onload = () => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    };

    return () => {
      // Cleanup the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-3308299494607073"
        data-ad-slot="1949423831"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default GoogleAds;