import { Minna } from "@consts";
import Helper from '@helper';
import Logger from '@helper/logger';
import RouterView from '@routes';
import Routes from '@routes/Routes';
import LocalStorage from '@store/LocalStorage';
import { useAppSelector as selector, useAppDispatch } from '@store/hooks';
import { RouteHistoryType, TabBarType, setDark, setFontSize, setFurigana, setLang, setName, setPremium, setRouteHistory, setScore, setTestPoint } from '@store/reducer/serviceSlice';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import GoogleAds from "./GoogleAds";
export enum ScoreType {
  VOCAB = 'vocab',
  GRAMMAR = 'grammar',
  KANJI_SCORE = 'kanjiScore',
  KAIWA = 'kaiwa'
}
export interface setScoreProp {
  id: number;
  key: ScoreType;
  value: any;
}
export interface getScoreProp {
  id: number;
  key: ScoreType;
}
interface RouteData {
  test?: TabBarType;
  home?: TabBarType;
  setting?: TabBarType;
}

export interface ScreenPropType {
  name: any; fontSize: any; dark: any; lang: any; furigana: any;scores: any;routeHistory:RouteHistoryType;
  toggleTheme: any; changeFontSize: any; changeLanguage: any; toggleFuri: any; testPoint: any; showModel: boolean;
  ScoreType: typeof ScoreType; getScore: ({id,key}: getScoreProp)=>any;premium:boolean;
    
  // mutation: any;query
  dispatch: any; selector: typeof selector;setRouteData: (data:RouteData)=>void;
  setDark: any; setName: any; setLang: any;setScores: ({ id, key, value }: setScoreProp )=>void; setPoint: ({ key, point }: { key: String, point: number })=>void;setUnlockPremium: (unlockCode: string)=> void;
  Routes: typeof Routes; useNavigate: any; createSearchParams: any; useLocation: any; setShowModel: Dispatch<SetStateAction<boolean>>
  // Minna
  Minna: any;
  // helper
  Logger: any; Helper: any
}

const App = () => {
  const log = Logger(0);
  const html = document.getElementById('html');
  const dispatch = useAppDispatch(); 
  const { dark, name, fontSize,lang,furigana,scores,routeHistory,testPoint,premium } = selector(({ service }) => service);

  // Route Data
  const setRouteData = (data:RouteData)=>{
    dispatch(setRouteHistory({...routeHistory,...data}));
  }

  // Unlock Model
  const [showModel,setShowModel] = useState(false);
  const setUnlockPremium = (unlockCode: string)=>{
    LocalStorage.setPremium({unlockCode,dispatch,setPremium})
  }
  // Unlock Model
  
  const setScores = ({ id, key, value }: setScoreProp ) => {
    const updatedScores = scores.map(({ vocab, grammar, kanjiScore, kaiwa }: any, index: number) => {
      if (id === index) {
        // update data
        let obj: any = { vocab, grammar, kanjiScore, kaiwa };
        obj[key] = value;
        return obj;
      } else {
        // no update data
        return { vocab, grammar, kanjiScore, kaiwa };
      }
    });
    LocalStorage.setScore({ scores:updatedScores, dispatch, setScore });
  }

  // setSetPoint
  const setPoint = ({key,point}:{key:String,point:number}) => {
    //testPoint,key,point
    LocalStorage.setPoint({ dispatch, setTestPoint, testPoint, key, point });
  }

  const getScore = ({id,key}: getScoreProp) => {
    return scores[id][key];
  }

  const toggleTheme = () => {
    LocalStorage.toggleTheme({ dark, dispatch, setDark });
  }

  const changeFontSize = (fontSize: number) => {
    LocalStorage.changeFontSize({ fontSize, dispatch, setFontSize });
  }

  const changeLanguage = (lang:string) => {
    LocalStorage.changeLanguage({ lang, setLang, dispatch });
  }

  const toggleFuri = () => {
    LocalStorage.toggleFuri({furigana,setFurigana,dispatch});
  }

  const screenProps: ScreenPropType = {
    // resources
    name, fontSize, dark, lang, furigana,scores,routeHistory,testPoint,premium,
    toggleTheme, changeFontSize, changeLanguage, toggleFuri, setScores,ScoreType,getScore,showModel,
    
    // mutation,query
    dispatch, selector,
    setDark, setName, setLang, setRouteData, setPoint,
    Routes, useNavigate, createSearchParams, useLocation,setShowModel,setUnlockPremium,
    // Minna
    Minna,
    // helper
    Logger, Helper
  }
  
  useEffect(() => {
    html!.setAttribute('class', dark);
    log.i("APP");
  }, []);

  return (
    <div className={`text-[#052d19] font-roboto text-${fontSize}px bg-background dark:bg-darkBackground dark:text-white`}>
      <RouterView screenProps={screenProps} />
      <GoogleAds />
    </div>
    )
};
export default App;